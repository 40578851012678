<template>
  <el-header
    height="60px"
    class="header"
    :class="{ 'with-hamburger': useVerticalMenu }">
    <!-- Megaport Logo -->
    <header-logo :show-hamburger="useVerticalMenu" />

    <!-- Navigation -->
    <div class="main-menu-container">
      <!-- Hamburger Button -->
      <el-button
        v-if="useVerticalMenu"
        id="hamburgerTriggerButton"
        ref="hamburgerButton"
        class="hamburger-button"
        @click="setShowHamburgerMenu(!showHamburgerMenu)">
        <i
          class="fas fa-bars fs-1-6rem"
          aria-hidden="true" />
      </el-button>

      <!-- useVerticalMenu controls whether the hamburger button should be shown (on mobile or narrow screen)  -->
      <!-- showHamburgerMenu controls whether the menu itself should be shown -->
      <!-- Main Menu -->
      <el-menu
        v-show="shouldShowMenu"
        ref="menu"
        router
        :mode="useVerticalMenu ? 'vertical' : 'horizontal'"
        :default-active="activeIndex"
        :ellipsis="false"
        :collapse-transition="false"
        :class="{ hamburger: useVerticalMenu }"
        :style="menuStyles"
        class="main-menu"
        @select="handleNavSelect">
        <!-- Main Menu Items (Critical Navigation) -->
        <!-- Dashboard -->
        <el-menu-item
          v-if="showDashboardMenu"
          index="/dashboard">
          <div class="flex-row-centered full-height">
            {{ $t('menu.dashboard') }}
          </div>
        </el-menu-item>
        <!-- Partner Admin -->
        <el-menu-item
          v-if="isPartnerAccount"
          index="/partner">
          <div class="flex-row-centered full-height">
            {{ $t('menu.partner-admin') }}
          </div>
        </el-menu-item>
        <!-- Services -->
        <el-menu-item
          id="intro-step-0"
          index="/services">
          <div class="flex-row-centered full-height">
            {{ $t('general.services') }}
          </div>
        </el-menu-item>
        <!-- Company Submenu -->
        <el-sub-menu
          v-if="showCompanySubmenu"
          index="company"
          data-submenu="company">
          <template #title>
            {{ $t('menu.company') }}
          </template>
          <!-- Company Profile -->
          <el-menu-item
            v-if="showCompanyProfileMenu"
            index="/company/profile">
            {{ $t('menu.company-profile') }}
          </el-menu-item>
          <!-- Megaport Marketplace Profile -->
          <el-menu-item
            v-if="hasCompany && showMpMarketplaceMenu"
            index="/marketplace/profile">
            {{ $t('menu.marketplace-profile') }}
          </el-menu-item>
          <!-- Billing Markets -->
          <el-menu-item
            v-if="hasCompany && showBillingMarketsMenu"
            index="/company/markets">
            {{ $t('menu.billing-markets') }}
          </el-menu-item>
          <!-- Manage Users -->
          <el-menu-item
            v-if="hasCompany && showManageUsersMenu"
            index="/company/users">
            {{ $t('menu.manage-users') }}
          </el-menu-item>
          <!-- Security Settings -->
          <el-menu-item
            v-if="hasCompany && showSecuritySettingsMenu"
            index="/company/security-settings">
            {{ $t('menu.company-security-settings') }}
          </el-menu-item>
          <!-- Account Contacts -->
          <el-menu-item
            v-if="hasCompany && showCompanySuppMenu"
            index="/company/support">
            {{ $t('menu.account-contacts') }}
          </el-menu-item>
          <!-- Company Entitlements -->
          <el-menu-item
            v-if="hasCompany && showEntitlementsMenu"
            index="/company/entitlements">
            {{ $t('menu.entitlements') }}
          </el-menu-item>
        </el-sub-menu>
        <!-- Tools Submenu -->
        <el-sub-menu
          index="tools"
          popper-class="tools-popover"
          data-submenu="tools">
          <template #title>
            {{ $t('menu.tools') }}
          </template>
          <!-- In-App Tools -->
          <el-menu-item-group :title="$t('menu.in-app-tools')">
            <!-- IX Telemetry -->
            <el-menu-item
              v-if="allowsIXItems"
              index="/tools/ix-flows">
              {{ $t('menu.ix-telemetry') }}
            </el-menu-item>
            <!-- MCR Looking Glass -->
            <el-menu-item index="/tools/mcr-looking-glass">
              {{ $t('menu.mcr-looking-glass') }}
            </el-menu-item>
            <!-- API Key Generator -->
            <el-menu-item
              v-if="allowsApiKeys"
              index="/tools/api-keys">
              {{ $t('menu.api-key-generator') }}
            </el-menu-item>
          </el-menu-item-group>
          <!-- Additional Tools -->
          <el-menu-item-group :title="$t('menu.additional-tools')">
            <!-- MegaIX Looking Glass -->
            <el-menu-item v-if="allowsIXItems">
              <a
                href="https://lg.megaport.com/"
                class="unstyled-menu-link"
                target="_blank"
                rel="noopener noreferrer">
                {{ $t('menu.ix-looking-glass') }}
                <external-link-indicator />
              </a>
            </el-menu-item>
            <!-- Quoting Tool -->
            <el-menu-item v-if="!isManagedAccount">
              <a
                :href="quotingToolUrl"
                class="unstyled-menu-link"
                target="_blank"
                rel="noopener noreferrer">
                {{ $t('menu.quoting-tool') }}
                <external-link-indicator />
              </a>
            </el-menu-item>
            <!-- Documentation -->
            <el-menu-item v-if="!disabledFeatures.knowledgeBase">
              <a
                href="https://docs.megaport.com/"
                class="unstyled-menu-link"
                target="_blank"
                rel="noopener noreferrer">
                {{ $t('general.documentation') }}
                <external-link-indicator />
              </a>
            </el-menu-item>
            <!-- API Documentation -->
            <el-menu-item>
              <a
                href="https://dev.megaport.com/"
                class="unstyled-menu-link"
                target="_blank"
                rel="noopener noreferrer">
                {{ $t('menu.api-documentation') }}
                <external-link-indicator />
              </a>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <!-- Megaport Marketplace -->
        <el-menu-item index="/marketplace">
          <div class="flex-row-centered full-height">
            {{ $t('productNames.marketplaceShort') }}
          </div>
        </el-menu-item>

        <!-- Secondary Menu Items -->
        <!-- What's New (AnnounceKit) -->
        <el-menu-item
          v-if="showAnnounceKit"
          :class="['ak-trigger', { 'first-of-second-group': showAnnounceKit }]"
          @click="handleNavSelect">
          <div class="flex-row-centered gap-0-5 full-height">
            {{ $t('menu.what-is-new') }}
            <announce-kit v-bind="announceKitProps" />
          </div>
        </el-menu-item>
        <!-- Service Status -->
        <el-menu-item
          index="/service-status"
          :class="{ 'first-of-second-group': !showAnnounceKit }">
          <div class="flex-row-centered full-height">
            {{ $t('service-status.service-status') }}
          </div>
        </el-menu-item>
        <!-- Event Log -->
        <el-menu-item
          v-if="allEvents.length"
          index="/event-log">
          <el-popover
            :placement="useVerticalMenu ? 'left' : 'bottom'"
            :title="$t('menu.event-log')"
            width="400"
            trigger="hover"
            :persistent="false">
            <div>
              <el-alert
                v-for="item in allEvents.slice(0, 5)"
                :key="item.hid"
                :title="item.title"
                :type="item.type"
                :closable="false"
                class="mb-5-px"
                show-icon />
              <template v-if="allEvents.length > 5">
                <hr />
                <div class="text-align-center vertical-align-middle">
                  <p class="d-inline-block mr-5-px">
                    {{ $t('menu.more-events') }}
                  </p>
                  <el-button
                    class="d-inline-block"
                    @click="$router.push('/event-log', () => {})">
                    {{ $t('menu.view-event-log') }}
                  </el-button>
                </div>
              </template>
            </div>
            <template #reference>
              <div class="flex-row-centered full-height">
                <span v-if="useVerticalMenu">
                  {{ $t('menu.events') }}
                </span>
                <span
                  v-if="successEvents.length"
                  class="fas fa-check-circle color-success fs-1-4rem" />
                <span
                  v-if="successEvents.length"
                  class="color-success event-text">
                  {{ successEvents.length }}
                </span>
                <span
                  v-if="warningEvents.length"
                  class="fas fa-exclamation-triangle color-warning fs-1-4rem" />
                <span
                  v-if="warningEvents.length"
                  class="color-warning event-text">
                  {{ warningEvents.length }}
                </span>
                <span
                  v-if="errorEvents.length"
                  class="fas fa-times-octagon color-danger fs-1-4rem" />
                <span
                  v-if="errorEvents.length"
                  class="color-danger event-text">
                  {{ errorEvents.length }}
                </span>
              </div>
            </template>
          </el-popover>
        </el-menu-item>
        <!-- User Submenu -->
        <el-sub-menu
          id="userProfileMenu"
          index="user"
          data-submenu="user">
          <template #title>
            {{ fullName }}
            <i
              class="fas fa-user"
              aria-hidden="true" />
          </template>
          <!-- User Profile -->
          <el-menu-item
            v-if="!isManagedContext"
            index="/user-profile">
            {{ $t('menu.user-profile') }}
          </el-menu-item>
          <!-- Notification Settings -->
          <el-menu-item
            v-if="allowsNotification"
            index="/notifications">
            {{ $t('menu.notification-settings') }}
          </el-menu-item>
          <el-menu-item>
            <a
              :href="feedbackUrl"
              class="unstyled-menu-link"
              target="_blank"
              rel="noopener noreferrer">
              {{ $t('menu.feedback') }}
              <external-link-indicator />
            </a>
          </el-menu-item>
          <!-- Log Out -->
          <el-menu-item @click="logout">
            {{ $t('menu.logout') }}
          </el-menu-item>
        </el-sub-menu>

        <!-- Language Submenu -->
        <!--
          Unable to use language dropdown component as it
          doesn't scale correctly in mobile menu
        -->
        <el-sub-menu
          index="language"
          data-submenu="language">
          <template #title>
            <span :class="{ 'sr-only': !useVerticalMenu }">
              {{ $t('general.languages') }}
            </span>
            <mu-mega-icon
              v-if="!useVerticalMenu"
              icon="Globe"
              class="dropdown-image"
              aria-hidden="true" />
          </template>
          <el-menu-item
            v-for="language in languages"
            :key="language.iso"
            @click="changeLanguage(language)">
            <span :class="{ 'color-primary': language.iso === $i18n.locale }">
              {{ language.name }}
            </span>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
  </el-header>
</template>

<script>
// External Tools
import { onBeforeUnmount } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

// Internal Tools
import { setActiveLanguage, languageSelections } from '@/i18n/SetupI18n.js'
import { getSurveyLink } from '@/utils/surveys.js'
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import { ROLE_TYPE_COMPANY_ADMIN } from '@/Globals'
// Components
import HeaderLogoComponent from '@/components/ui-components/HeaderLogo.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'
import MuMegaIcon from '@/mega-ui/components/leafs/mega-icon/MuMegaIcon.vue'
import AnnounceKit from '@/third-party-integrations/AnnounceKit.vue'

export default {
  name: 'HeaderMenu',

  components: {
    'header-logo': HeaderLogoComponent,
    'external-link-indicator': ExternalLinkIndicator,
    'announce-kit': AnnounceKit,
    'mu-mega-icon': MuMegaIcon,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      languages: languageSelections,
      useVerticalMenu: false,
      showHamburgerMenu: false,
    }
  },

  computed: {
    ...mapState('Auth', ['data']),
    ...mapState('UserProfile', ['profile']),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', [
      'hasFeatureFlag',
      'hasAuth',
      'isPartnerAccount',
      'isManagedAccount',
      'isDirectAccount',
      'isPartnerSupported',
    ]),
    ...mapGetters('Company', ['hasCompany']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Notifications', ['reversedEventLog']),
    /**
     * Specify breakpoint to show normal or hamburger menu
     */
    menuBreakpoint() {
      return this.isPartnerAccount ? 1550 : 1390
    },
    /**
     * Determine whether to show the dashboard page
     */
    showDashboard() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    /**
     * Get the event information and split it into the different types.
     */
    allEvents() {
      return this.reversedEventLog
    },
    successEvents() {
      return this.allEvents.filter(event => event.type === 'success')
    },
    warningEvents() {
      return this.allEvents.filter(event => event.type === 'warning')
    },
    errorEvents() {
      return this.allEvents.filter(event => event.type === 'error')
    },
    /**
     * Work out which menu item is selected.
     */
    activeIndex() {
      return this.$route.meta.navigationMenuItem || this.$route.path
    },
    fullName() {
      return this.profile.firstName && this.profile.lastName
        ? `${this.profile.firstName} ${this.profile.lastName}`
        : this.data.fullName
    },
    showBillingMarketsMenu() {
      return this.hasAuth('financials') && this.isFeatureEnabled('PAYS_BILL') && !this.isManagedContext
    },
    showCompanySubmenu() {
      return (
        this.showCompanyProfileMenu ||
        (this.hasCompany && this.showMpMarketplaceMenu) ||
        (this.hasCompany && this.showBillingMarketsMenu) ||
        (this.hasCompany && this.showManageUsersMenu) ||
        (this.hasCompany && this.showCompanySuppMenu)
      )
    },
    showCompanyProfileMenu() {
      return this.isFeatureEnabled('COMPANY_PROFILE_ENABLED') && !this.isManagedContext
    },
    showDashboardMenu() {
      return !this.disabledFeatures.dashboard && this.isFeatureEnabled('DASHBOARD_ENABLED')
    },
    showManageUsersMenu() {
      return this.hasAuth('company_admin')
    },
    showSecuritySettingsMenu() {
      return this.hasAuth('company_admin')
    },
    showCompanySuppMenu() {
      return (
        ((this.isPartnerAccount || this.isPartnerSupported || this.isManagedAccount) && !this.isManagedContext) ||
        this.isDirectAccount
      )
    },
    showEntitlementsMenu() {
      return this.hasAuth('company_admin') && this.hasFeatureFlag('aws_marketplace_portal')
    },
    showMpMarketplaceMenu() {
      if (this.marketplaceUserProfile?.companyName) {
        return true // we have a populated profile
      }

      // if we have no existing marketplace profile we need to be an admin to create one
      return (
        this.hasAuth('company_admin') && this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && !this.isManagedContext
      )
    },
    showAnnounceKit() {
      return !this.disabledFeatures.announceKit && this.$appConfiguration.announceKit?.mainWidget
    },
    announceKitProps() {
      return {
        catchClick: '.ak-trigger',
        widget: `https://announcekit.co/widgets/v2/${this.$appConfiguration.announceKit.mainWidget}`,
        user: { id: this.data.personUid },
        data: {
          accountType: this.data.accountType,
          role: this.profile.position,
          environment: this.$appConfiguration.environmentName,
        },
      }
    },
    allowsApiKeys() {
      return this.hasFeatureFlag('m2mauthentication') && [ROLE_TYPE_COMPANY_ADMIN].includes(this.profile.position)
    },
    allowsIXItems() {
      return !this.disabledFeatures.productIX
    },
    allowsNotification() {
      if (this.isManagedContext) return false
      if (this.isManagedAccount) return this.isFeatureEnabled('NOTIFICATIONS_ENABLED')
      return true
    },
    feedbackUrl() {
      return getSurveyLink('portalFeedback', { partyId: this.profile.partyId })
    },
    shouldShowMenu() {
      return (this.useVerticalMenu && this.showHamburgerMenu) || !this.useVerticalMenu
    },
    menuStyles() {
      return !this.useVerticalMenu ? 'background-color: transparent;' : ''
    },
    quotingToolUrl() {
      return this.$appConfiguration.quotingTool.url
    },
  },

  mounted() {
    const mediaQuery = window.matchMedia(`(width < ${this.menuBreakpoint}px)`)

    const handleResize = () => {
      this.useVerticalMenu = mediaQuery.matches

      if (!this.useVerticalMenu) {
        this.closeSubMenus()
        this.setShowHamburgerMenu(false)
      }
    }

    handleResize()

    mediaQuery.addEventListener('change', handleResize)
    document.addEventListener('click', this.handleOutsideClick)

    onBeforeUnmount(() => {
      mediaQuery.removeEventListener('change', handleResize)
      document.removeEventListener('click', this.handleOutsideClick)
    })
  },
  methods: {
    ...mapActions('Auth', ['logout']),

    /**
     * Process the selection from the menu. It operates on the "index" field.
     */
    handleNavSelect() {
      this.setShowHamburgerMenu(false)
      // TODO: This is a hack to fix the issue where the submenu is  not closing when the user clicks on a menu item.
      // This is a temporary fix and should be removed once the issue is fixed.
      setTimeout(() => {
        this.closeSubMenus()
      }, 1)
    },
    /**
     * Close all open sub-menus in the vertical menu mode.
     */
    closeSubMenus() {
      const openSubMenus = document.querySelectorAll('.el-sub-menu.is-opened')
      openSubMenus.forEach(subMenu => {
        if (!this.useVerticalMenu || !subMenu.classList.contains('is-active')) {
          const navName = subMenu.getAttribute('data-submenu')

          this.$refs.menu.close(navName)
        }
      })
    },
    changeLanguage(language) {
      // Send analytic event first so that the old language is captured in the event
      captureEvent(`navbar.change-language.${language.iso}.click`)
      setActiveLanguage(language)
      this.handleNavSelect()
    },

    setShowHamburgerMenu(showHamburgerMenu) {
      if (!showHamburgerMenu) {
        this.closeSubMenus()
      }

      this.showHamburgerMenu = showHamburgerMenu
    },

    handleOutsideClick(event) {
      if (!this.useVerticalMenu || !this.showHamburgerMenu) return

      const menu = this.$refs.menu?.$el
      const hamburgerButton = this.$refs.hamburgerButton?.$el

      if (!menu?.contains(event.target) && !hamburgerButton?.contains(event.target)) {
        this.setShowHamburgerMenu(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-header.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-inline: 1.6rem;
  background-color: var(--mp-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 55%);

  &.with-hamburger {
    padding-right: 0;
  }
}

.el-menu-item {
  font-size: 1.6rem;
  padding: 0 15px;
}

.event-text {
  padding: 0 5px 0 3px;
}

.hamburger-button {
  color: var(--color-text-secondary);
  font-size: 1rem;
  position: absolute;
  right: 11px;
  top: 11px;
  padding: 10px;
  border: none;
}
.hamburger-button:hover {
  color: var(--color-text-primary);
}

.dropdown-image {
  height: 16px;
  width: 16px;
  color: var(--color-info);
}

.mr-5-px {
  margin-right: 5px;
}
.mb-5-px {
  margin-bottom: 5px;
}

.hamburger {
  position: fixed;
  top: 61px;
  right: 0;
  width: 350px;
  height: auto;
  max-height: calc(100vh - 61px);
  overflow-y: auto;
  background-color: var(--mp-header-background-color);
  border-left: 1px solid var(--card-border-color);
  border-bottom: 1px solid var(--card-border-color);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
.el-header.header.with-hamburger {
  justify-content: space-between;

  .main-menu-container {
    flex-basis: initial;
    justify-self: end;
  }
}

.main-menu-container {
  display: inline-block;
  flex-basis: 100%;
  height: 100%;
}

.main-menu {
  .el-sub-menu__title,
  .el-sub-menu__icon-arrow {
    font-size: 1.6rem;
  }

  .el-sub-menu__title {
    display: flex;
    align-items: center;

    .fas.fa-user {
      margin-left: 0.4rem;
    }
  }

  &.el-menu--horizontal {
    --el-menu-hover-text-color: var(--el-text-color-primary);
    --el-menu-hover-bg-color: var(--color-white);

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none !important;

    li[role='menuitem'] .el-icon-arrow-down {
      margin-top: 0;
      margin-left: 0.4rem;
      font-size: 1.2rem;
    }

    li[role='menuitem']:first-of-type,
    li[role='menuitem'].first-of-second-group {
      margin-left: auto;
    }

    .el-menu-item {
      padding: 0 1.6rem;
      color: var(--color-info);

      &:not(.is-disabled):focus {
        background-color: var(--mp-body-background-color);
      }
    }

    &:not(.el-menu--collapse) .el-sub-menu__title {
      padding-block: 0;
      padding-inline: 1.6rem 3.2rem;
      color: var(--color-info);

      .el-sub-menu__icon-arrow {
        right: 1.6rem;
      }
    }

    li[role='menuitem']:last-of-type .el-sub-menu__title {
      width: 3.2rem;
      height: 6rem;
      padding: 0;

      .el-sub-menu__icon-arrow {
        right: -1rem;
      }
    }

    .el-menu-item.is-active,
    .el-sub-menu.is-active .el-sub-menu__title {
      padding-top: 2px;
      border-bottom: 4px solid var(--color-megaport-red);
      color: var(--el-text-color-primary) !important;
      background-color: var(--mp-body-background-color);

      .el-sub-menu__icon-arrow {
        top: calc(50% + 1px);
      }
    }
  }

  &.el-menu--vertical {
    .el-sub-menu.is-active .el-sub-menu__title {
      background-color: var(--mp-body-background-color);
    }
  }
}

.el-popper .el-menu--horizontal .el-menu {
  --el-menu-hover-text-color: var(--el-text-color-primary);
  --el-menu-hover-bg-color: var(--color-white);

  .el-sub-menu__title,
  .el-menu-item {
    color: var(--color-info);
  }

  .el-menu-item.is-active,
  .el-sub-menu.is-active .el-sub-menu__title {
    color: var(--el-text-color-primary);
  }
}

.tools-popover {
  .el-menu-item-group {
    .el-menu-item-group__title {
      padding-left: 1rem !important;
      color: var(--color-text-regular);
      text-transform: uppercase;
      font-weight: 700;
    }

    [role='menuitem'] {
      padding-left: 1.5rem !important;
    }
  }
}

.unstyled-menu-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}
</style>
