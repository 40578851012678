import sdk from '@megaport/api-sdk'

import * as globals from '@/Globals.js'

const coreState = () => ({
  portsList: [],
  isLoadingPortsList: true,
  isLoadingService: false,
  isLoadingMultipleServices: false,
  serviceList: [],
})

const actions = {
  async fetchPorts({ commit }) {
    commit('setIsLoading', { key: 'isLoadingPortsList', value: true })
    const results = await sdk.instance.ports([
      globals.G_PROVISIONING_NEW,
      globals.G_PROVISIONING_DESIGN,
      globals.G_PROVISIONING_DEPLOYABLE,
      globals.G_PROVISIONING_CONFIGURED,
      globals.G_PROVISIONING_LIVE,
      globals.G_PROVISIONING_CANCELLED,
      globals.G_PROVISIONING_CANCELLED_PARENT,
    ])
    commit('setPortsList', results)
    commit('setIsLoading', { key: 'isLoadingPortsList', value: false })
  },
  async fetchService({ commit }, requestObj) {
    commit('setIsLoading', { key: 'isLoadingService', value: true })
    const results = await sdk.instance.product(requestObj.productUid).get(true)
    commit('appendProductsList', results)
    commit('setIsLoading', { key: 'isLoadingService', value: false })
  },
  async fetchMultipleServices({ dispatch, commit }, requestObj) {
    commit('setIsLoading', { key: 'isLoadingMultipleServices', value: true })
    for (const uid of requestObj.productUids) {
      await dispatch('fetchService', { productUid: uid })
    }
    commit('setIsLoading', { key: 'isLoadingMultipleServices', value: false })
  },
  clearProductsList({ commit }) {
    commit('clearProductsList')
  },
}

const coreGetters = {
  mcrPorts(state) {
    return state.portsList.filter(port => {
      return port.virtual && port.productType === globals.G_PRODUCT_TYPE_MCR2
    })
  },
  mcrPortsLive(state, getters) {
    return getters.mcrPorts.filter(port => {
      return port.provisioningStatus === globals.G_PROVISIONING_LIVE
    })
  },
  portServices: state => portProductUid => {
    return state.serviceList.filter(service => {
      return service.productUid === portProductUid
    })
  },
}

const mutations = {
  setIsLoading(state, data) {
    state[data.key] = data.value
  },
  setPortsList(state, data) {
    state.portsList = data
  },
  appendProductsList(state, data) {
    const serviceListClone = state.serviceList.slice()
    serviceListClone.push(data)
    state.serviceList = serviceListClone
  },
  clearProductsList(state) {
    state.serviceList = []
  },
}

export default {
  namespaced: true,
  actions,
  getters: coreGetters,
  mutations,
  state: coreState(),
}
