import sdk from '@megaport/api-sdk'

import { mpApp_$t } from '@/mpApp.js'

// Initial state
const coreState = {
  managedCompaniesList: [],
  isLoading: false,
}

const getters = {
  managedAccountIds: state => {
    return state.managedCompaniesList.map(company => company.accountRef)
  },
  managedAccountName: state => {
    return state.managedCompaniesList.map(company => company.accountName)
  },
  getManagedAccountFromId: state => companyUid => {
    return state.managedCompaniesList.find(a => a.companyUid === companyUid)
  },
}

const actions = {
  // List of managed company
  getManagedCompanies: async context => {
    context.commit('setLoading', true)

    try {
      const results = await sdk.instance.managedCompanies().getManagedCompanies()
      context.commit('setManagedCompanies', results)
      return Promise.resolve()
    } catch (err) {
      context.commit(
        'Notifications/notifyMessage',
        {
          title: `${mpApp_$t('general.unknown-error')} - ${err.data?.message || mpApp_$t('general.no-info')}`,
          type: 'error',
        },
        { root: true },
      )
      return Promise.reject(err)
    } finally {
      context.commit('setLoading', false)
    }
  },
  // Create a new managed company
  createNewManagedCompanies: async (context, payload) => {
    context.commit('setLoading', true)
    try {
      await sdk.instance.managedCompanies().createManagedCompany({
        accountRef: payload.accountRef,
        accountName: payload.accountName,
        techSupportPhone: payload.techSupportPhone,
        techSupportEmail: payload.techSupportEmail,
      })
      context.dispatch('getManagedCompanies')
      return Promise.resolve()
    } catch (err) {
      const message = err.data?.message || mpApp_$t('general.no-info')
      return Promise.reject({ ...err, message })
    } finally {
      context.commit('setLoading', false)
    }
  },

  // Update the company data via the API
  updateManagedCompanies: async (
    context,
    { companyUid, accountName, accountRef, techSupportPhone, techSupportEmail },
  ) => {
    context.commit('setLoading', true)
    try {
      const result = await sdk.instance
        .managedCompanies()
        .update(companyUid, { accountName, accountRef, techSupportPhone, techSupportEmail })
      context.dispatch('getManagedCompanies')
      return result
    } catch (err) {
      const message = err.data?.message || mpApp_$t('general.no-info')
      return Promise.reject({ ...err, message })
    } finally {
      context.commit('setLoading', false)
    }
  },
}

const mutations = {
  setManagedCompanies(state, payload) {
    state.managedCompaniesList = payload
  },
  logout(state) {
    state.managedCompaniesList = null
  },
  setLoading(state, data) {
    state.isLoading = data
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
