import { v1 as uuid } from 'uuid'
import { ElMessage, ElMessageBox } from 'element-plus'
import { h } from 'vue'

import { mpApp_$t } from '@/mpApp.js'
import { mpDate } from '@/helpers.js'

// Initial state
const coreState = {
  eventLog: [],
}

const getters = {
  reversedEventLog(state) {
    return state.eventLog.slice().sort((a, b) => {
      if (a.ts < b.ts) return 1
      if (a.ts > b.ts) return -1
      return 0
    })
  },
}

const actions = {
  /**
   * Define message box modal to show when ordering/editing/removing IBM connections
   */
  showIbmReminderModal() {
    ElMessageBox.confirm({
      title: mpApp_$t('ibm.modal-title'),
      message: h('div', null, [
        h('p', null, mpApp_$t('ibm.order-request-sent')),
        h('p', null, mpApp_$t('ibm.order-request-reminder')),
      ]),
      showCancelButton: true,
      closeOnClickModal: false,
      confirmButtonText: mpApp_$t('ibm.visit-portal'),
      cancelButtonText: mpApp_$t('general.not-now'),
    })
      .then(() => {
        window.open('https://cloud.ibm.com/login', '_blank').focus()
      })
      .catch(() => {
        /* empty function is intentional */
      })
  },
}

const mutations = {
  /**
   * Generates an event and pushes it to the event log, then notifies the user using ElMessage.
   * @param {object} state The state object for the store.
   * @param {object} payload The payload containing the message information.
   * @param {string} payload.title The text content of the message.
   * @param {string} payload.message Some additional optional information to display in the event log.
   * @param {'success' | 'info' | 'warning' | 'error'} payload.type The type/variant of the message.
   * @param {number} payload.duration The optional duration in milliseconds to display the message.
   */
  notifyMessage(state, payload) {
    const { title, message, type, duration } = payload

    const messageEvent = {
      title,
      message,
      type,
      ts: Date.now(),
      time: mpDate(),
      hid: uuid(),
    }

    // Add the message to the event log
    state.eventLog.push(messageEvent)

    // Display the message in the UI
    ElMessage({
      message: title,
      type,
      duration: duration || 3000,
      showClose: true,
      offset: 65,
    })
  },
  clearEventLog(state) {
    state.eventLog = []
  },
  logout(state) {
    state.eventLog = []
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
