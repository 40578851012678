import * as VueSentry from '@sentry/vue'
import { createApp } from 'vue' // Change this line
import { ElNotification } from 'element-plus'

import { mpApp } from '@/mpApp.js'
import git_hash from '@/gitVersion.js'

const APP_VERSION = import('@/../package.json').version

export class Sentry {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_SENTRY === 'true'
  #displayedSentryError = false
  #isProd = false

  /**
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (this.#disabled) return

    if (!appConfiguration.sentry) {
      console.error('Sentry disabled due to missing configuration in environment settings.')
      this.#disabled = true
      return
    }
    const config = appConfiguration.sentry

    const devEnv = appConfiguration.environmentName === 'development'
    const testingEnv = devEnv || /^(qa|uat)\d*$/i.test(appConfiguration.environmentName)
    this.#isProd = appConfiguration.isProduction

    VueSentry.init({
      app: createApp({}), // Use this instead of Vue
      environment: appConfiguration.environmentName,
      // Sentry is better at handing release tracking if we strictly follow semver in reported versions
      release: `Portal@${APP_VERSION}`,
      dsn: config.dsn,
      integrations: [
        VueSentry.browserTracingIntegration({
          // Use the route from vue router for tracing
          beforeNavigate: context => ({
            ...context,
            // There are no child routes in Portal so matched is always an array with 1 entry
            name: mpApp.config.globalProperties.$route.matched[0].path ?? context.name,
          }),
        }),
        VueSentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      debug: false,
      enabled: true,
      normalizeDepth: 10, // Allow reported data to exceed the default depth of 3
      tracesSampleRate: config.tracesSampleRate ?? 0,
      tracePropagationTargets: [appConfiguration.apiUrl],
      replaysSessionSampleRate: config.normalReplayRate ?? 0,
      replaysOnErrorSampleRate: config.errorReplayRate ?? 0,
      ignoreErrors: [
        /ResizeObserver loop/,
        // Cancelled Request Errors
        /^AbortError/,
        /^canceled$/,
        / aborted\.?$/,
        // Session Timeout Error
        /^Bad session token - try logging in again\.$/,
        // Vue Router Warnings
        /^Avoided redundant navigation to current location: "[^"]+"\.$/,
        /^Navigation cancelled from "[^"]+" to "[^"]+" with a new navigation\.$/,
        /^Redirected when going from "[^"]+" to "[^"]+" via a navigation guard\.$/,
        // Google Auth Internal Errors
        /^[A-Z]$/,
        // Permissions Error
        /is not allowed by the user agent or the platform in the current context, possibly because the user denied permission\.$/,
      ],
      initialScope: {
        tags: { git_hash },
      },
      beforeSend: (event, hint) => {
        // If an error happens in development or qa environment, alert the user with a non-closing message.
        if (testingEnv) {
          console.log('Sentry...', event) // eslint-disable-line no-console
          if (hint.originalException) {
            console.error(hint.originalException)
            if (hint.originalException.cause) console.error(hint.originalException.cause)
          }

          // Only allow one pop-up at a time since it doesn't automatically close
          if (!this.#displayedSentryError) {
            ElNotification.error({
              message: 'Sentry Error Generated - please see console for details',
              duration: 0,
              position: 'bottom-left',
              onClose: () => {
                this.#displayedSentryError = false
              },
            })
            this.#displayedSentryError = true
          }
        }
        // Don't send messages in development environment
        return devEnv ? null : event
      },
    })
  }

  setUser(payload) {
    if (this.#disabled) return

    VueSentry.getCurrentScope().setUser({
      id: payload.personUid,
      // TODO: Figure out the legality of sending this data to Sentry
      // username: payload.username,
      // email: payload.email,
      ip_address: '{{auto}}',
      accountType: payload.accountType,
    })
  }

  clearUser() {
    if (this.#disabled) return

    VueSentry.getCurrentScope().setUser(null)
  }

  captureException(exception, hint) {
    if (this.#disabled) return

    VueSentry.captureException(exception, hint)
  }

  /**
   * Tells sentry that required integration configuration is missing in the current environment.
   * @param {string} integrationName The name of the integration that is missing configuration in the environment settings.
   */
  captureMissingConfigException(integrationName) {
    if (this.#disabled || !this.#isProd) return

    VueSentry.captureException(new Error(`${integrationName} not configured for ${window.location.origin}`))
  }
}
