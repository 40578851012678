import { createApp } from 'vue'
import * as vClickOutside from 'v-click-outside-x'

import AppComponent from '@/App.vue'
import filtersPlugin from '@/plugins/filters.js'
import globalsPlugin from '@/plugins/globals.js'
import providersPlugin from '@/plugins/providers.js'
import config from '@/appConfiguration.js'

/** The Vue application instance for the Portal */
const mpApp = createApp(AppComponent)

// Setup global properties
mpApp.config.globalProperties.$appConfiguration = config

// Register plugins
mpApp.use(vClickOutside)
mpApp.use(filtersPlugin)
mpApp.use(globalsPlugin)
mpApp.use(providersPlugin)

export { mpApp }

/**
 * A top-level export of `app.config.globalProperties.$t` for ease of use.
 * @type {import('vue').ComponentCustomProperties['$t']}
 */
// Don't directly set this to `app.config.globalProperties.$t` as it won't have been set yet
export const mpApp_$t = (...args) => mpApp.config.globalProperties.$t(...args)
