import { createStore } from 'vuex'

import actions from './RootActions.js'
import mutations from './RootMutations.js'
import ApplicationContext from './modules/ApplicationContext.js'
import Auth from './modules/Auth.js'
import Company from './modules/Company.js'
import Marketplace from './modules/Marketplace.js'
import Markets from './modules/Markets.js'
import RouteFiltering from './modules/RouteFiltering.js'
import Notifications from './modules/Notifications.js'
import ServiceFilters from './modules/ServiceFilters.js'
import Services from './modules/Services.js'
import UserNotifications from './modules/UserNotifications.js'
import UserProfile from './modules/UserProfile.js'
import Users from './modules/Users.js'
import IXTypes from './modules/IXTypes.js'
import IXFlows from './modules/IXFlows.js'
import Products from './modules/Products.js'
import MegaportCloudRouter from './modules/MegaportCloudRouter.js'
import MVE from './modules/MVE.js'
import ManagedCompanies from './modules/ManagedCompanies.js'
import ServiceStatus from './modules/ServiceStatus.js'
import debugPlugin from './VuexDebugPlugin.js'

const plugins = []

if (import.meta.env.VITE_RUNTIME_ENV === 'test' && import.meta.env.VITE_DEBUG_VUEX_MUTATIONS === 'true') {
  plugins.push(debugPlugin)
}

const storeConfig = {
  strict: import.meta.env.VITE_RUNTIME_ENV !== 'production',
  modules: {
    Auth,
    Company,
    Marketplace,
    Markets,
    RouteFiltering,
    Notifications,
    ServiceFilters,
    Services,
    UserNotifications,
    UserProfile,
    Users,
    IXTypes,
    IXFlows,
    Products,
    MegaportCloudRouter,
    MVE,
    ManagedCompanies,
    ApplicationContext,
    ServiceStatus,
  },
  state: () => ({
    needsReload: false,
    showSidebar: true,
    language: {},
    g2NewLogin: false,
    isUrlTriggeredG2Review: false,
  }),
  actions,
  mutations,
  plugins,
}

const store = createStore(storeConfig)

export default store
export { storeConfig }
