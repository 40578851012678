import { createRouter, createWebHistory } from 'vue-router'

import {
  ACCOUNT_TYPE_DIRECT_CUSTOMER,
  ACCOUNT_TYPE_PARTNER,
  ACCOUNT_TYPE_MANAGED_ACCOUNT,
  ACCOUNT_TYPE_ADMIN,
} from '@/Globals.js'
import { resolveServicesPage } from '@/utils/MapDataUtils'

import { routeGuards } from './routeGuards'

// Direct dynamic imports instead of defineAsyncComponent
const ApproveConnectionComponent = () => import('@/components/approve-connection/ApproveConnection.vue')
const ProfileSettingsComponent = () => import('@/components/marketplace/profile/ProfileSettings.vue')
const DashboardComponent = () => import('@/components/Dashboard.vue')
const ServicesComponent = () => import('@/components/services/services.vue')
const ServiceStatusComponent = () => import('@/components/service-status/ServiceStatus.vue')
const CreateMegaportComponent = () => import('@/components/CreateMegaport.vue')
const EditPortPage = () => import('@/pages/EditPort.vue')
const CreateConnectionComponent = () => import('@/components/CreateConnection.vue')
const EditConnectionComponent = () => import('@/components/EditConnection.vue')
const MoveConnectionsComponent = () => import('@/components/move-connections/MoveConnections.vue')
const UserProfileComponent = () => import('@/components/user-profile/UserProfile.vue')
const ChangeEmailComponent = () => import('@/components/user-profile/ChangeEmail.vue')
const UserNotificationsComponent = () => import('@/components/UserNotifications.vue')
const CompanyProfileComponent = () => import('@/components/company/CompanyProfile.vue')
const CompanyMarketsComponent = () => import('@/components/company/CompanyMarkets.vue')
const CompanyUsersComponent = () => import('@/components/company/CompanyUsers.vue')
const CompanyEntitlementsComponent = () => import('@/components/company/CompanyEntitlements.vue')
const CompanySecuritySettings = () => import('@/components/company/CompanySecuritySettings.vue')
const CompanySupportComponent = () => import('@/components/company/CompanySupport.vue')
const SignupVerifyEmailComponent = () => import('@/components/authentication/signup/SignupVerifyEmail.vue')
const SignupSelectionComponent = () => import('@/components/authentication/signup/SignupSelection.vue')
const SignupFormComponent = () => import('@/components/authentication/signup/SignupForm.vue')
const SignupConfirmEmailComponent = () => import('@/components/authentication/signup/SignupConfirmEmail.vue')
const SignupGoogleComponent = () => import('@/components/authentication/signup/SignupGoogle.vue')
const LoginFormComponent = () => import('@/components/authentication/login/LoginForm.vue')
const LoginSSOComponent = () => import('@/components/authentication/login/LoginSSO.vue')
const PostLoginComponent = () => import('@/components/authentication/PostLogin.vue')
const AuthComponent = () => import('@/components/authentication/Authentication.vue')
const VendorOnboardingComponent = () => import('@/components/authentication/VendorOnboarding.vue')
const ForgotPasswordComponent = () => import('@/components/authentication/reset-password/ForgotPassword.vue')
const ResetPasswordComponent = () => import('@/components/authentication/reset-password/ResetPassword.vue')
const CompanyTradingNameComponent = () => import('@/components/authentication/CompanyTradingName.vue')
const SetupMfaComponent = () => import('@/components/authentication/mfa/SetupMfa.vue')
const MfaFormComponent = () => import('@/components/authentication/mfa/MfaForm.vue')
const MegaportMarketplaceProfileComponent = () => import('@/components/marketplace/MegaportMarketplaceProfile.vue')
const MegaportMarketplaceComponent = () => import('@/components/marketplace/MegaportMarketplace.vue')
const DebugComponent = () => import('@/components/Debug.vue')
const SessionEventLogComponent = () => import('@/components/events/EventLog.vue')
const IXFlowsView = () => import('@/views/IXFlows.vue')
const MCRLookingGlassView = () => import('@/components/mcr-looking-glass/MCRLookingGlassView.vue')
const MVEPage = () => import('@/pages/Mve.vue')
const PartnerAdmin = () => import('@/pages/PartnerAdmin.vue')
const ManagedAdmin = () => import('@/pages/ManagedAdmin.vue')
const ApiKeysPage = () => import('@/components/api-keys/ApiKeys.vue')
const AwsOnboarding = () => import('@/components/AwsOnboarding.vue')

export const routes = [
  /***** Signup-related routes *****/

  {
    path: '/signup',
    component: SignupSelectionComponent,
    meta: {
      title: 'page-titles.signup',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/signup-email',
    component: SignupFormComponent,
    meta: {
      title: 'page-titles.signup',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/signup-google',
    name: 'SignupGoogle',
    component: SignupGoogleComponent,
    meta: {
      title: 'page-titles.signup',
      requiresLogin: true,
      layout: {
        hideHeader: true,
        hideSidebar: true,
      },
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/verify-email',
    component: SignupVerifyEmailComponent,
    meta: {
      title: 'page-titles.email-confirmation',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  /**
   * This is the URL from the confirmation email, only change this if the link
   * in the email is also being changed
   */
  {
    path: '/verify',
    component: SignupConfirmEmailComponent,
    meta: {
      title: 'page-titles.email-confirmation',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/verify/sso',
    component: SignupConfirmEmailComponent,
    props: {
      isSSO: true,
    },
    meta: {
      title: 'page-titles.email-confirmation',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },

  /***** Login-related routes *****/

  {
    path: '/login',
    component: AuthComponent,
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/login/sso',
    component: LoginSSOComponent,
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/login/username/:username?',
    component: LoginFormComponent,
    props: true,
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/login/help',
    component: LoginFormComponent,
    props: {
      showHelpText: true,
    },
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/loginas/:targetUsername?',
    component: LoginFormComponent,
    name: 'login-as',
    props: {
      loginAs: true,
    },
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/post-login',
    component: PostLoginComponent,
    meta: {
      title: 'page-titles.logging-in',
      requiresLogin: false,
      showContextBanner: false,
    },
  },
  {
    // This path can't change to kebab case as it's coded in the BE email
    path: '/passwordReset',
    component: ResetPasswordComponent,
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordComponent,
    meta: {
      title: 'page-titles.login',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/company-trading-name',
    name: 'CompanyTradingName',
    component: CompanyTradingNameComponent,
    meta: {
      title: 'company.company-trading-name',
      requiresLogin: true,
      layout: {
        hideHeader: true,
        hideSidebar: true,
      },
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/setup-mfa',
    name: 'SetupMfa',
    component: SetupMfaComponent,
    meta: {
      title: 'page-titles.setup-mfa',
      requiresLogin: true,
      layout: {
        hideHeader: true,
        hideSidebar: true,
      },
      reloadSafe: false,
      showContextBanner: false,
    },
  },
  {
    path: '/mfa',
    name: 'MfaFormComponent',
    component: MfaFormComponent,
    meta: {
      title: 'page-titles.mfa',
      reloadSafe: false,
      showContextBanner: false,
    },
  },

  /***** User-related routes *****/

  {
    path: '/user-profile',
    component: UserProfileComponent,
    meta: {
      title: 'page-titles.user-profile',
      requiresLogin: true,
      view: 'user-profile',
      reloadSafe: false,
      showContextBanner: false,
    },
  },
  {
    path: '/notifications',
    component: UserNotificationsComponent,
    meta: {
      title: 'page-titles.user-notifications',
      requiresLogin: true,
      view: 'user-notifications',
      reloadSafe: true,
      showContextBanner: false,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },
  {
    path: '/notifications/:destinationName',
    component: UserNotificationsComponent,
    meta: {
      title: 'page-titles.user-notifications',
      requiresLogin: true,
      view: 'user-notifications',
      reloadSafe: false,
      showContextBanner: false,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/notifications',
    },
  },
  /* This component is used for the change email feature in the user-profile, the
   * backend will send an email with /verifyEmail#token to finalise the email change.
   * I've renamed the component to ChangeEmail to reflect that even though the path is verify.
   */
  {
    path: '/verifyEmail',
    component: ChangeEmailComponent,
    meta: {
      title: 'page-titles.email-confirmation',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
      navigationMenuItem: '/user-profile',
    },
  },

  /***** Company-related routes *****/

  {
    path: '/company/profile',
    component: CompanyProfileComponent,
    meta: {
      title: 'page-titles.company-profile',
      requiresLogin: true,
      view: 'company-profile',
      reloadSafe: false,
      showContextBanner: true,
      runtimeFlag: {
        name: 'COMPANY_PROFILE_ENABLED',
        redirect: resolveServicesPage(),
      },
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },
  {
    path: '/company/markets/market/:marketId/:action',
    component: CompanyMarketsComponent,
    meta: {
      title: 'page-titles.billing-markets',
      requiresLogin: true,
      auth: 'financials',
      view: 'company-markets',
      reloadSafe: false,
      showContextBanner: true,
      runtimeFlag: {
        name: 'PAYS_BILL',
        redirect: resolveServicesPage(),
      },
      navigationMenuItem: '/company/markets',
    },
  },
  {
    path: '/company/markets',
    component: CompanyMarketsComponent,
    meta: {
      title: 'page-titles.billing-markets',
      requiresLogin: true,
      auth: 'financials',
      view: 'company-markets',
      reloadSafe: true,
      showContextBanner: true,
      runtimeFlag: {
        name: 'PAYS_BILL',
        redirect: resolveServicesPage(),
      },
    },
  },
  {
    path: '/company/users',
    component: CompanyUsersComponent,
    meta: {
      title: 'page-titles.company-users',
      requiresLogin: true,
      auth: 'company_admin',
      view: 'company-users',
      reloadSafe: false,
      showContextBanner: true,
    },
  },
  {
    path: '/company/users/new',
    component: CompanyUsersComponent,
    meta: {
      title: 'page-titles.company-users',
      requiresLogin: true,
      auth: 'company_admin',
      view: 'company-users',
      reloadSafe: false,
      showContextBanner: true,
      navigationMenuItem: '/company/users',
    },
  },
  {
    path: '/company/entitlements',
    component: CompanyEntitlementsComponent,
    meta: {
      title: 'page-titles.aws-entitlements',
      requiresLogin: true,
      auth: 'company_admin',
      view: 'company-users',
      reloadSafe: false,
      showContextBanner: true,
    },
  },
  {
    path: '/company/security-settings',
    component: CompanySecuritySettings,
    meta: {
      title: 'page-titles.company-security-settings',
      requiresLogin: true,
      auth: 'company_admin',
      view: 'company-security-settings',
      reloadSafe: true,
      showContextBanner: true,
    },
  },
  {
    path: '/company/users/edit/:employmentId',
    component: CompanyUsersComponent,
    meta: {
      title: 'page-titles.company-users',
      requiresLogin: true,
      auth: 'company_admin',
      view: 'company-users',
      reloadSafe: false,
      showContextBanner: true,
      navigationMenuItem: '/company/users',
    },
  },
  {
    path: '/company/support',
    component: CompanySupportComponent,
    meta: {
      title: 'page-titles.company-support',
      requiresLogin: true,
      showContextBanner: false,
      accountTypes: [ACCOUNT_TYPE_DIRECT_CUSTOMER, ACCOUNT_TYPE_PARTNER, ACCOUNT_TYPE_MANAGED_ACCOUNT],
    },
  },

  /***** Service-related routes *****/

  {
    path: '/services',
    component: ServicesComponent,
    meta: {
      title: 'page-titles.services',
      requiresLogin: true,
      view: 'dashboard',
      reloadSafe: true,
      scrollTop: true,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },
  {
    path: '/service-status',
    component: ServiceStatusComponent,
    meta: {
      title: 'page-titles.service-status',
      requiresLogin: true,
      view: 'dashboard',
      reloadSafe: true,
      scrollTop: true,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },
  {
    path: '/create-megaport/:serviceType/:productUid?',
    component: CreateMegaportComponent,
    name: 'CreatePort',
    meta: {
      title: 'page-titles.create-port', // Have this here as a fallback value (the title should be dynamically set in routeGuards.js)
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/edit-megaport/:serviceId',
    component: EditPortPage,
    name: 'EditPort',
    meta: {
      title: 'page-titles.edit-port',
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/add-port-to-lag/:aggregationId/:lagId',
    component: CreateMegaportComponent,
    meta: {
      title: 'page-titles.create-port',
      requiresLogin: true,
      reloadSafe: false,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/mve/:productUid?',
    component: MVEPage,
    name: 'CreateMve',
    meta: {
      title: 'page-titles.mve', // Have this here as a fallback value (the title should be dynamically set in routeGuards.js)
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/create-connection/:productUid?',
    component: CreateConnectionComponent,
    name: 'CreateConnection',
    meta: {
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/edit-connection/:serviceId',
    component: EditConnectionComponent,
    name: 'EditConnection',
    meta: {
      title: 'page-titles.edit-connection',
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/move-connections/:productUid',
    component: MoveConnectionsComponent,
    name: 'MoveConnections',
    meta: {
      title: 'page-titles.move-connections',
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      showContextBanner: true,
      checkManagedAccountSwitch: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },
  {
    path: '/approve-connection/:productUid',
    component: ApproveConnectionComponent,
    meta: {
      title: 'page-titles.approve-connection',
      requiresLogin: true,
      reloadSafe: true,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/services',
    },
  },

  /***** Marketplace-related routes *****/
  {
    path: '/marketplace/profile',
    alias: '/exchange/profile',
    component: ProfileSettingsComponent,
    meta: {
      title: 'page-titles.marketplace-profile',
      requiresLogin: true,
      view: 'marketplace-profile',
      reloadSafe: false,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },
  {
    path: '/marketplace/:companyPath',
    alias: '/exchange/:companyPath',
    component: MegaportMarketplaceProfileComponent,
    meta: {
      title: 'page-titles.marketplace-profile',
      requiresLogin: true,
      view: 'marketplace-profile',
      reloadSafe: false,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/marketplace',
    },
  },
  {
    path: '/marketplace',
    alias: '/exchange',
    component: MegaportMarketplaceComponent,
    meta: {
      title: 'page-titles.marketplace',
      requiresLogin: true,
      view: 'marketplace',
      reloadSafe: true,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
    },
  },

  /***** Partner-related routes *****/

  {
    path: '/partner',
    component: PartnerAdmin,
    meta: {
      title: 'page-titles.partner-admin',
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      accountTypes: [ACCOUNT_TYPE_PARTNER],
    },
  },
  {
    path: '/partner/managed/:companyUid',
    component: ManagedAdmin,
    meta: {
      title: 'page-titles.managed-account',
      requiresLogin: true,
      reloadSafe: false,
      scrollTop: true,
      accountTypes: [ACCOUNT_TYPE_PARTNER],
      navigationMenuItem: '/partner',
    },
  },

  /***** Tool-related routes *****/

  {
    path: '/tools/mcr-looking-glass/:mcr?',
    component: MCRLookingGlassView,
    name: 'McrLookingGlass',
    meta: {
      title: 'page-titles.mcr-looking-glass',
      requiresLogin: true,
      reloadSafe: true,
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/tools/mcr-looking-glass',
    },
  },
  {
    path: '/tools/ix-flows/:productUid?',
    component: IXFlowsView,
    meta: {
      title: 'page-titles.ix-flows',
      requiresLogin: true,
      view: 'ix-flows-view',
      showContextBanner: true,
      accountTypes: [
        ACCOUNT_TYPE_ADMIN,
        ACCOUNT_TYPE_DIRECT_CUSTOMER,
        ACCOUNT_TYPE_PARTNER,
        ACCOUNT_TYPE_MANAGED_ACCOUNT,
      ],
      navigationMenuItem: '/tools/ix-flows',
    },
  },
  {
    path: '/tools/api-keys',
    component: ApiKeysPage,
    meta: {
      title: 'page-titles.api-keys',
      requiresLogin: true,
      reloadSafe: true,
      showContextBanner: false,
      view: 'api-keys',
    },
  },

  /***** Other routes *****/

  {
    path: '/dashboard',
    component: DashboardComponent,
    meta: {
      title: 'page-titles.dashboard',
      requiresLogin: true,
      view: 'dashboard',
      reloadSafe: true,
      showContextBanner: true,
      runtimeFlag: {
        name: 'DASHBOARD_ENABLED',
        redirect: '/services',
      },
    },
  },
  {
    path: '/onboard/:vendor',
    component: VendorOnboardingComponent,
    meta: {
      title: 'page-titles.onboard',
      requiresLogin: false,
      view: 'auth',
      reloadSafe: true,
    },
  },
  {
    path: '/event-log',
    component: SessionEventLogComponent,
    meta: {
      title: 'page-titles.event-log',
      requiresLogin: true,
      view: 'event-log',
      reloadSafe: true,
      showContextBanner: true,
    },
  },
  {
    path: '/debug',
    component: DebugComponent,
    meta: {
      title: 'page-titles.debug',
      requiresLogin: true,
      reloadSafe: true,
      showContextBanner: true,
    },
  },
  {
    path: '/aws-marketplace',
    component: AwsOnboarding,
    meta: {
      title: 'page-titles.aws-onboarding',
      requiresLogin: false,
      reloadSafe: true,
      showContextBanner: false,
    },
  },
  {
    path: '/g2',
    meta: {
      requiresLogin: true,
    },
    redirect: resolveServicesPage(),
  },
  {
    path: '/self-test',
  },
  {
    // Replace the "*" catch-all with new syntax
    path: '/:pathMatch(.*)*',
    redirect: import.meta.env.VITE_BYPASS_AUTHENTICATION === 'true' ? resolveServicesPage() : '/login',
  },
]

const publicPath =
  import.meta.env.VITE_RUNTIME_ENV === 'production' && import.meta.env.VITE_PUBLIC_PATH
    ? import.meta.env.VITE_PUBLIC_PATH.toString()
    : '/'
const router = createRouter({
  routes,
  history: createWebHistory(publicPath),
  scrollBehavior(to, from, savedPosition) {
    // Allow override of normal scrolling so we don't end up half way down
    // pages where it would be more sensible to end up at the top
    if (to.meta.scrollTop) {
      return { x: 0, y: 0 }
    }
    // If we're navigating to a hash, scroll up a bit so it's not right on the top line.
    if (to.hash) {
      return {
        el: to.hash,
        offset: { x: 0, y: 10 },
      }
    }
    // If we were there before, go back to that place and otherwise to the top
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

routeGuards(router)

export default router
