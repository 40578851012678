import { createI18n } from 'vue-i18n'
import { Settings } from 'luxon'
import { has } from 'lodash'

import en from '@/i18n/en-setup.js'
import store from '@/store/Store.js'
import integrations from '@/third-party-integrations/integrations'

/**
 * This is the master ordered list of languages for the user to select from. This is used internally
 * and in the language selection in the UI.
 *
 * The first language specified is the default and is automatically loaded as the fallback language.
 */
export const languageSelections = [
  { code: 'en', region: 'US', iso: 'en-US', file: 'en-setup.js', name: 'English' },
  { code: 'de', region: 'DE', iso: 'de-DE', file: 'de-setup.js', name: 'Deutsch' },
  { code: 'fr', region: 'FR', iso: 'fr-FR', file: 'fr-setup.js', name: 'Français' },
  { code: 'ja', region: 'JP', iso: 'ja-JP', file: 'ja-setup.js', name: '日本語 (Japanese)' },
  { code: 'es', region: 'ES', iso: 'es-ES', file: 'es-setup.js', name: 'Español' },
  { code: 'pt', region: 'BR', iso: 'pt-BR', file: 'pt-BR-setup.js', name: 'Português Brasileiro' },
]

// Define a glob pattern to import all language files
const languageModules = import.meta.glob('@/i18n/*.js')

const loadLanguageMessages = async lang => {
  const languageFile = `/src/i18n/${lang}`
  if (languageModules[languageFile]) {
    const module = await languageModules[languageFile]()
    return module.default
  } else {
    throw new Error(`Language file ${languageFile} not found`)
  }
}

const loadedLanguages = [languageSelections[0].iso]
let i18n = null

/**
 * Get the selected language from cookie/localStorage. Falls back to en-US if not found.
 *
 * @returns The selected language record
 */
const readSelectedLanguage = () => {
  const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
  // Look for one that includes the region, fall back to just language
  const localStore =
    window.localStorage.getItem('mp_lang_region') || window.localStorage.getItem('mp_lang') || undefined
  const resolved = cookies.get('mp_lang_region') || cookies.get('mp_lang') || localStore
  const selectedLanguage = languageSelections.find(lang => lang.iso === resolved || lang.code === resolved)
  // Avoid the possibility of it being set to something we don't know how to translate.
  return selectedLanguage || languageSelections[0]
}

/**
 * Set the language as a cookie as the primary information.
 * Some ad blockers block cookies so mirror to localStorage (if on client).
 *
 * @param {object} lang The selected language iso abbreviation
 */
const writeSelectedLanguage = ({ code, iso }) => {
  // Set lang code
  document.cookie = `mp_lang=${code}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang', code)
  // Set lang iso
  document.cookie = `mp_lang_region=${iso}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang_region', iso)
}

/**
 * Uses the cached language definition and sets it as current, or if not cached, dynamically loads the
 * specified language.
 *
 * @param {string} lang Language record from languageSelections
 */
export const setActiveLanguage = async (lang = readSelectedLanguage()) => {
  writeSelectedLanguage(lang)

  if (!loadedLanguages.includes(lang.iso)) {
    const messages = await loadLanguageMessages(lang.file)
    loadedLanguages.push(lang.iso)

    i18n.global.setLocaleMessage(lang.iso, messages)
    loadedLanguages.push(lang.iso)
  }

  setI18nLanguage(lang.iso)
  store.commit('setLanguage', lang)
}

/**
 * Set the i18n plugin language
 * Makes the specified language work throughout the app.
 *
 * @param {string} language The language abbreviation
 */
export const setI18nLanguage = language => {
  if (import.meta.env.VITE_RUNTIME_ENV === 'test') {
    return
  }
  // I18n plugin
  i18n.global.locale.value = language
  // Luxon dates
  Settings.defaultLocale = language
  Settings.resetCaches()
  // Html
  updateHtml()
  // PostHog
  integrations.postHog.setSessionData({ language })
  integrations.postHog.setUserData({ 'Latest Language': language })
}

/**
 * Updates the lang attribute on the html and sets an appropriate class on the body so you can adjust formatting
 * for different languages if required.
 */
const updateHtml = () => {
  document.documentElement.lang = i18n.global.locale.value

  const removableClasses = []
  document.body.classList.forEach(className => {
    if (className.startsWith('locale-')) {
      removableClasses.push(className)
    }
  })
  for (const className of removableClasses) {
    document.body.classList.remove(className)
  }
  document.body.classList.add(`locale-${i18n.global.locale.value}`)
}

const initializeI18n = async () => {
  // If i18n is already initialized, return the existing instance.
  if (i18n) return

  // Load default language messages asynchronously
  const defaultLanguageMessages = await loadLanguageMessages(languageSelections[0].file)

  // We cannot load up i18n when running tests because
  // we lose the ability to set up the v-t directive
  if (import.meta.env.VITE_RUNTIME_ENV === 'test') {
    i18n = createI18n({
      legacy: false, // Set to false to use Composition API
      globalInjection: true, // Enables global injection of $t
      locale: languageSelections[0].iso,
      fallbackLocale: languageSelections[0].iso,
      messages: { [languageSelections[0].iso]: defaultLanguageMessages },
      silentTranslationWarn: true,
      warnHtmlMessage: false,
      warnHtmlInMessage: 'off',
      fallbackWarn: false,
    })
  } else {
    // Instantiate i18n with the default language (English)
    i18n = createI18n({
      legacy: false, // Set to false to use Composition API
      globalInjection: true, // Enables global injection of $t
      locale: readSelectedLanguage().iso,
      fallbackLocale: languageSelections[0].iso,
      messages: { [languageSelections[0].iso]: defaultLanguageMessages },
      silentTranslationWarn: true,
      warnHtmlMessage: false,
      fallbackWarn: false,
      missing: (locale, key) => {
        // Only log the standard warning if the key does not exist in the locale or the fallback language.
        if (!has(en, key)) {
          console.warn(`[intlify] Not found '${key}' key in '${locale}' locale messages.`)
        }

        return key
      },
      // postTranslation: str => `X${str}X` // Uncomment this to display everything translated with a prefix & suffix of X
    })

    // Initialise with the default or preselected active language.
    await setActiveLanguage()
  }
}

export const getI18n = async () => {
  await initializeI18n()
  return i18n
}

export default i18n
