import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  prefixLists: [],
}

const getters = {
  prefixLists(state) {
    return state.prefixLists || []
  },
}

const actions = {
  getPrefixLists(context, payload) {
    context.commit('setPrefixLists', [])
    return new Promise((resolve, reject) => {
      if (payload.productUid) {
        sdk.instance
          .mcrPrefixLists(payload.productUid)
          .list()
          .then(res => {
            context.commit('setPrefixLists', res)
            return resolve()
          })
          .catch(err => {
            console.warn(err)
            return reject(err)
          })
      }
    })
  },
}

const mutations = {
  setPrefixLists(state, prefixLists) {
    state.prefixLists = prefixLists
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
